/* eslint-disable no-console, react/jsx-props-no-spreading */

import React, { useContext, useState, useCallback, useEffect } from "react";

import { DataTable, Button, Card, Modal, InlineStack } from "@shopify/polaris";
import { useLocation, useNavigate } from "react-router-dom";

import { SubscriptionContext } from "./SubscriptionContext";
import { useFetch, useProfile } from "../utils";
import LoadingAnimation from "./LoadingAnimation";

const moment = require("moment");

function SubscriptionHistoryTable() {
  const { activeSubscriptions, reload } = useContext(SubscriptionContext);
  const authFetch = useFetch();

  const navigate = useNavigate();
  const location = useLocation();
  const [profile] = useProfile();

  const [activeModal, setActiveModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleActive = useCallback(
    () => setActiveModal(!activeModal),
    [activeModal]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("confirm")) {
      setLoadingTable(true);
      authFetch(`/api/charges/${query.get("confirm")}/confirm`)
        .then((r) => r.json())
        .then(() => {
          console.log("Confirmed charge.");
          reload();
          navigate(`/`);
        })
        .catch(() => { })
        .finally(() => {
          setLoadingTable(false);
        });
    }
  }, [authFetch, location.search, navigate, reload]);

  const confirmCancelModal = (subscription) => {
    setSelectedSubscription(subscription);
    handleActive();
  };

  const handleConfirmCancel = (subscription) => {
    setLoadingButton(true);
    setLoadingTable(true);
    authFetch(`api/subscriptions/${subscription.id}/unsubscribe`, {
      headers: { "Content-Type": "application/json" },
    })
      .then(async (response) => {
        const unsubscribeData = await response.json();
        // check for error response
        if (!response.ok) {
          console.log("Error handling uninstall request");
          // get error message from body or default to response status
          const error =
            (unsubscribeData && unsubscribeData.message) || response.status;
          throw error;
        }
        console.log("Uninstall finished successfully");
      })
      .catch((error) => {
        console.error("Error handling uninstall request", error);
      })
      .finally(() => {
        setSelectedSubscription(null);
        setLoadingButton(false);
        reload();
        setLoadingTable(false);
        handleActive();
      });
  };

  const activeSubscriptionRows = (activeSubscription) =>
    activeSubscription &&
    activeSubscription.length > 0 &&
    activeSubscription.map((subscription) => [
      subscription.name,
      subscription.state,
      `$${subscription.cost}`,
      subscription.annual ? "Annually" : "Monthly",
      moment(subscription.date_subscribed).toString(),
      subscription.trial_days,
      subscription.trial_end_date,
      subscription.date_unsubscribed ? subscription.date_unsubscribed : "N/A",
      <InlineStack wrap={false} align="end">
        {!subscription.active && (
          <Button
            url={subscription.confirmation_url}
            primary
          >
            Activate{!profile.user.account_owner ? " (Requires Owner)" : ""}
          </Button>
        )}
        {profile && profile.user && profile.user.support && (
          <Button destructive onClick={() => confirmCancelModal(subscription)}>
            Cancel
          </Button>
        )}
      </InlineStack>,
    ]);

  useEffect(() => {
    if (activeSubscriptions !== null) {
      setLoadingTable(false);
    }
  }, [activeSubscriptions]);

  return activeSubscriptions && activeSubscriptions.length > 0 ? (
    <Card
      title="Subscription History"
      actions={[{ onAction: reload, content: "Reload" }]}
      padding="0"
    >
      <Modal
        title="Cancel Subscription?"
        open={activeModal}
        onClose={handleActive}
        primaryAction={{
          content: "Cancel",
          onAction: () => {
            handleConfirmCancel(selectedSubscription);
          },
          loading: loadingButton,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: "Close",
            onAction: handleActive,
          },
        ]}
      >
        <Modal.Section>
          <p>
            Are you sure you want to cancel your subscription? This action can’t
            be undone.
          </p>
        </Modal.Section>
      </Modal>
      {loadingTable ? (
        <LoadingAnimation />
      ) : (
        <DataTable
          verticalAlign="middle"
          columnContentTypes={[
            "text",
            "text",
            "numeric",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={[
            "Name",
            "Status",
            "Amount",
            "Billing",
            "Created",
            "Trial Days",
            "Trial Ends",
            "Cancelled",
            "",
          ]}
          rows={activeSubscriptionRows(activeSubscriptions)}
        />
      )}
    </Card>
  ) : null;
}

export default SubscriptionHistoryTable;
